@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fadeIn {
  opacity: 1;
  animation: fadeIn 1s;
}

.fadeOut {
  opacity: 0;
  animation: fadeOut 1s;
}

.projects-container-outer {
  height: fit-content;
  padding: 1.6rem 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 5rem 0rem 0rem 0rem;
}

.projects-container-outer > img {
  position: absolute;
  max-height: 34rem;
  left: -10rem;
}

.projects-container-inner {
  width: 100%;
  max-width: 40rem;
  z-index: 1;
}

@media only screen and (min-width: 1024px) {
  .projects-container-outer {
    margin: 15rem 0rem 0rem 0rem;
  }
  .projects-container-inner {
    width: 100%;
    max-width: 82rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

@media only screen and (min-width: 1440px) {
  .projects-container-outer {
    margin: 0rem 0rem 0rem 0rem;
  }
  .projects-container-outer > img {
    left: 0;
    top: -20rem;
    max-height: 100%;
  }
  .projects-container-inner {
    max-width: 88rem;
  }
}
