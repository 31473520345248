@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fadeIn {
  opacity: 1;
  animation: fadeIn 1s;
}

.fadeOut {
  opacity: 0;
  animation: fadeOut 1s;
}

.intro-container-outer {
  height: 100vh;
  min-height: 60rem;
  max-height: 75rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.intro-container-outer > img {
  max-width: 35rem;
  position: absolute;
  right: 0;
}

.intro-container-inner {
  padding: 0rem 0rem 0rem 1.6rem;
  min-height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  z-index: 1;
  justify-content: center;
}

.intro-container-inner .intro-contact__div {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 5rem;
}

.intro-container-inner .intro__img {
  max-width: 25rem;
}

.intro-info__div {
  display: flex;
  flex-direction: column;
  max-width: fit-content;
  z-index: 1;
}

.intro-info__div > span {
  font-family: "Mulish", sans-serif;
  font-size: 3.2rem;
  font-weight: 800;
  line-height: 5rem;
  color: black;
  transition: color 1s;
}

.intro-info__div > p {
  font-size: 3.2rem;
  color: #5784f9;
  margin: 0;
  line-height: 5rem;
  font-weight: 800;
}

.intro-info__div .underLine__img {
  min-width: 100%;
}

.intro-info__span-outline-blue {
  color: transparent;
  -webkit-text-stroke: 1.5px #5784f9;
  /* stroke width and color */
}

.intro-info__span-outline-black {
  color: transparent;
  -webkit-text-stroke: 1.5px #25282b;
  transition: all 1s;
  /* stroke width and color */
}

.intro-icon__div {
  display: flex;
  margin-top: 2rem;
}

.intro-icon__div svg {
  min-width: 3rem;
  max-height: 3rem;
  margin-right: 1.4rem;
}

body.dark-mode .intro-info__div > span {
  color: white;
  transition: color 1s;
}

body.dark-mode .intro-info__span-outline-black {
  color: transparent;
  -webkit-text-stroke: 1.5px #ffffff;
  /* stroke width and color */
}

@media only screen and (min-width: 768px) {
  .intro-container-outer > img {
    min-width: 45rem;
  }
  .intro-info__div {
    position: relative;
    bottom: 3rem;
  }
  .intro-info__div > span {
    font-family: "Mulish", sans-serif;
    font-size: 4.8rem;
    font-weight: 800;
    line-height: 8rem;
  }
  .intro-info__div > p {
    font-size: 4.8rem;
    line-height: 8rem;
  }
  .intro-contact__div .intro__img {
    max-width: 30rem;
  }
}

@media only screen and (min-width: 1024px) {
  .intro-container-outer {
    padding: 0rem 5% 0rem 5%;
  }
  .intro-container-outer > img {
    min-width: 60rem;
  }
  .intro-info__div {
    margin: auto 0;
  }
  .intro-container-inner {
    padding: 13.2rem 3.8rem 2rem 3.8rem;
  }
  .intro-container-inner .intro__img {
    max-width: 35rem;
  }
  .intro-info__span-outline-blue {
    color: transparent;
    -webkit-text-stroke: 2px #5784f9;
    /* stroke width and color */
  }
  .intro-info__span-outline-black {
    color: transparent;
    -webkit-text-stroke: 2px #25282b;
    /* stroke width and color */
  }
}

@media only screen and (min-width: 1440px) {
  .intro-container-outer {
    padding: 0rem 10% 0rem 10%;
  }
  .intro-container-outer > img {
    min-width: 65rem;
  }
  .intro-container-inner .intro__img {
    max-width: 35rem;
  }
  .intro-info__div > span {
    font-family: "Mulish", sans-serif;
    font-size: 5.6rem;
    font-weight: 800;
    line-height: 10rem;
  }
  .intro-info__div > p {
    font-size: 5.6rem;
    line-height: 10rem;
  }
}

@media only screen and (min-width: 1920px) {
  .intro-container-outer > img {
    min-width: 77rem;
  }
  .intro-container-inner .intro__img {
    max-width: 45rem;
  }
}
