@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fadeIn {
  opacity: 1;
  animation: fadeIn 1s;
}

.fadeOut {
  opacity: 0;
  animation: fadeOut 1s;
}

.button-action,
.button-info,
.button-submitted {
  background-color: transparent;
  color: black;
  transition: color 1s;
  font-size: 1.4rem;
  padding: 0.8rem 0.8rem;
  margin-right: 0.4rem;
  display: inline-block;
  transition: 0.35s ease;
  min-height: 4rem;
}

.button-info {
  border: 1px solid #5784f9;
  font-weight: 700;
  border-radius: 0.4rem;
}

.button-info:hover {
  transform: translateY(-0.5rem);
  box-shadow: 3px 3px 10px -5px black;
}

.button-action,
.button-submitted {
  border-bottom: 0.3rem solid #5784f9;
  display: flex;
  align-items: center;
  border-radius: 0.4rem;
  font-weight: 700;
}

.button-action > svg,
.button-submitted > svg {
  margin-right: 0.8rem;
}

.button-action > img,
.button-submitted > img {
  max-width: 2rem;
  max-height: 1.5rem;
  margin-right: 0.8rem;
}

.button-submitted {
  border-bottom: 0.3rem solid #2df591;
}

.button-submitted:hover {
  box-shadow: inset 0 -3.25em 0 0 #2df591;
}

.button-action:hover {
  box-shadow: inset 0 -3.25em 0 0 #5784f9;
}

body.dark-mode .button-info,
body.dark-mode .button-action,
body.dark-mode .button-submitted {
  color: white;
  transition: color 1s;
  transition: 0.35s ease;
}

body.dark-mode .button-info:hover {
  transform: translateY(-0.5rem);
  box-shadow: 3px 3px 10px -5px white;
}
