@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fadeIn {
  opacity: 1;
  animation: fadeIn 1s;
}

.fadeOut {
  opacity: 0;
  animation: fadeOut 1s;
}

.about-container-outer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  padding: 2rem 0rem 0rem 0rem;
  background-color: #f3f3f3;
}

.about-container-outer > h1 {
  margin: 0rem 0rem 8rem 0rem !important;
}

.about-container-outer > img {
  position: absolute;
  max-width: 12rem;
  right: 0;
  top: 5rem;
}

.about-container-inner {
  max-width: 40rem;
  padding: 1.6rem 3.8rem;
  display: flex;
  flex-direction: column;
}

.about-container-inner > p {
  font-weight: 400;
  font-size: 1.4rem;
  margin: 0.5rem 0rem;
  color: black;
  transition: color 1s;
  line-height: 2.2rem;
}

.about-container-inner > span {
  font-weight: 600;
  font-size: 2.4rem;
  font-family: "Mulish", sans-serif;
  margin: 0.5rem 0rem;
}

.about-container-inner > span:first-of-type {
  font-weight: 700;
  color: black;
  transition: color 1s;
}

.about-container-inner > span:last-of-type,
.about-container-inner #about-name__span {
  color: #5784f9;
}

.about-container-inner > img {
  margin: 1rem 0rem;
  filter: brightness(110%);
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.about-skills__div {
  padding: 0rem 3.8rem;
  display: flex;
  flex-direction: column;
  max-width: 40rem;
}

.about-skills__div > span {
  font-weight: 700;
  font-family: "Mulish", sans-serif;
  font-size: 1.6rem;
  letter-spacing: -0.1rem;
  margin-bottom: 1.5rem;
  color: black;
  transition: color 1s;
}

.about-skills__div > div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.about-skills__div > div > button {
  margin-bottom: 0.4rem;
}

body.dark-mode .about-container-inner > p {
  color: white;
  transition: color 1s;
}

body.dark-mode .about-container-inner > span:first-of-type {
  color: white;
  transition: color 1s;
}

body.dark-mode .about-skills__div > span {
  color: white;
  transition: color 1s;
}

@media only screen and (min-width: 1024px) {
  .about-container-outer {
    padding: 5rem 5% 0rem 5%;
  }
  .about-container-outer > img {
    position: absolute;
    max-width: 36rem;
    right: 0;
    top: 15rem;
  }
  .about-container-inner {
    align-self: flex-start;
    max-width: 80rem;
    text-align: left;
    display: grid;
    grid-template-columns: 45% 55%;
    grid-template-rows: 100%;
    grid-template-areas: 'img text';
  }
  .about-container-inner > img {
    max-width: 30rem;
    grid-area: img;
  }
  .about-container-inner > * {
    grid-area: text;
  }
  .about-container-inner > span:first-of-type {
    margin: 11rem 0rem 0rem 0rem;
  }
  .about-container-inner #about-name__span {
    margin: 14rem 0rem 0rem 0rem;
  }
  .about-container-inner > p:first-of-type {
    margin: 17.5rem 0rem 0rem 0rem;
  }
  .about-container-inner > p:last-of-type {
    margin: 25rem 0rem 0rem 0rem;
  }
  .about-skills__div {
    align-self: flex-start;
    max-width: none;
    align-items: flex-start;
  }
}

@media only screen and (min-width: 1440px) {
  .about-container-outer {
    padding: 5rem 10% 20rem 10%;
  }
  .about-container-outer > img {
    position: absolute;
    max-width: 48rem;
    right: 0;
    top: 10rem;
  }
  .about-container-inner {
    grid-template-columns: 47% 53%;
    max-width: 88rem;
  }
  .about-container-inner > img {
    max-width: 35rem;
  }
  .about-container-inner > span:first-of-type {
    font-size: 2.4rem;
    margin: 10rem 0rem 0rem 0rem;
  }
  .about-container-inner #about-name__span {
    font-size: 2.6rem;
    margin: 14rem 0rem 0rem 0rem;
  }
  .about-container-inner > p:first-of-type {
    font-size: 1.6rem;
    margin: 18.5rem 0rem 0rem 0rem;
  }
  .about-container-inner > p:last-of-type {
    font-size: 1.6rem;
    margin: 29rem 0rem 0rem 0rem;
  }
}
