@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fadeIn {
  opacity: 1;
  animation: fadeIn 1s;
}

.fadeOut {
  opacity: 0;
  animation: fadeOut 1s;
}

.pageTitle {
  font-size: 3.6rem;
  margin: 4rem 0rem 8rem 0rem;
  min-height: 5rem;
  color: black;
  transition: color 1s;
  z-index: 1;
}

body.dark-mode .pageTitle {
  color: white;
  transition: color 1s;
}

@media only screen and (min-width: 1024px) {
  .pageTitle {
    font-size: 4.8rem;
    min-height: 6.5rem;
  }
}
