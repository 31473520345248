@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fadeIn {
  opacity: 1;
  animation: fadeIn 1s;
}

.fadeOut {
  opacity: 0;
  animation: fadeOut 1s;
}

.contact-container-outer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 5rem 0rem 0rem 0rem;
}

#contact-hand {
  position: absolute;
  top: -4rem;
  right: 0rem;
}

.contact-form-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 40rem;
  padding: 1.6rem 0.8rem;
}

.contact-form-container .contact_name-input,
.contact-form-container .contact_email-input,
.contact-form-container .contact_message-input,
.contact-form-container .contact_name-input--error,
.contact-form-container .contact_email-input--error,
.contact-form-container .contact_message-input--error {
  min-width: 100%;
  margin: 1rem 0rem 2rem 0rem;
  min-height: 3rem;
  border: none;
  border-radius: 0.4rem;
  padding: 0.8rem;
  font-family: "Lato", sans-serif;
  font-size: 1.4rem;
}

.contact-form-container .contact_name-input:focus,
.contact-form-container .contact_email-input:focus,
.contact-form-container .contact_message-input:focus {
  outline: 2px solid #5784f9;
}

.contact-form-container .contact_name-input,
.contact-form-container .contact_email-input,
.contact-form-container .contact_message-input {
  outline: 1px solid #e8ecf4;
}

.contact-form-container .contact_name-input--error,
.contact-form-container .contact_email-input--error,
.contact-form-container .contact_message-input--error {
  outline: 2px solid #ed4337;
}

.contact-form-container text-area {
  min-height: 9rem;
}

.contact-form-container label {
  font-size: 1.4rem;
  font-weight: 700;
  color: black;
  transition: color 1s;
}

.contact-form-container button {
  margin: 0rem 0rem 0rem auto;
}

#footer-blob {
  min-width: 100%;
}

body.dark-mode .contact-form-container label {
  color: white;
  transition: color 1s;
}

@media only screen and (min-width: 1024px) {
  .contact-container-outer {
    margin: 15rem 0rem 0rem 0rem;
  }
  #contact-hand {
    min-width: 33rem;
    position: absolute;
    top: -32rem;
    right: 0rem;
  }
  .contact-form-container {
    min-width: 60rem;
  }
  .contact-form-container label {
    font-size: 1.6rem;
  }
}

@media only screen and (min-width: 1920px) {
  #contact-hand {
    min-width: 48rem;
    position: absolute;
    top: -45rem;
    right: 0rem;
  }
}
