@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fadeIn {
  opacity: 1;
  animation: fadeIn 1s;
}

.fadeOut {
  opacity: 0;
  animation: fadeOut 1s;
}

.projectCard {
  margin-bottom: 2rem;
  border-top-left-radius: 1.8rem;
  border-top-right-radius: 1.8rem;
  overflow: hidden;
  max-width: 40rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.projectCard > img {
  max-height: 20.5rem;
  min-width: 100%;
}

.projectCard-inner__div {
  padding: 1.6rem;
}

.projectCard-inner__div > h1 {
  margin: 0rem 0rem 1rem 0rem;
  font-size: 2.4rem;
  font-weight: 800;
  color: #25282b;
  letter-spacing: -1.5px;
}

.projectCard-inner__div > p {
  font-size: 1.4rem;
  line-height: 2rem;
  color: black;
  transition: color 1s;
}

.projectCard-inner__div > div {
  display: flex;
}

.projectCard-inner__div a {
  text-decoration: none;
}

body.dark-mode .projectCard-inner__div > p {
  color: white;
  transition: color 1s;
}
