@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fadeIn {
  opacity: 1;
  animation: fadeIn 1s;
}

.fadeOut {
  opacity: 0;
  animation: fadeOut 1s;
}

body {
  width: 100%;
  margin: 0;
  background-color: #f3f3f3;
  transition: background 1.5s;
}

body.dark-mode {
  background-color: #535353;
}

body.dark-mode .p-test {
  color: aqua;
}

.App {
  height: fit-content;
  min-height: 100vh;
  min-width: 37.5rem;
}

.App,
#root {
  background-color: inherit;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 62.5%;
  color: #25282b;
}

body {
  font-family: Mulish, sans-serif;
}

img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}

button {
  outline: none;
  user-select: none;
  cursor: pointer;
  border: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
button {
  font-family: Mulish;
}

span,
p {
  font-family: Lato;
}

.placeholder-container {
  border: 2px solid #b6b3b3;
  height: 50vh;
}
