@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fadeIn {
  opacity: 1;
  animation: fadeIn 1s;
}

.fadeOut {
  opacity: 0;
  animation: fadeOut 1s;
}

.navBar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem;
  width: 100%;
  position: fixed;
  z-index: 2;
  background-color: transparent;
  min-height: 8.2rem;
  transition: backGround 1.5s;
}

.navBar-container > div {
  display: flex;
  align-items: center;
}

.logo {
  margin-right: 2.8rem;
}

.nav-item--desktop,
.logo {
  font-size: 2.2rem;
  font-weight: 700;
  cursor: pointer;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  height: 5rem;
  color: black;
  transition: color 1s;
  background-size: 0% 3px;
  background-repeat: no-repeat;
  transition: color 1s, background-size 0.3s;
  background-position: 0 100%;
  background-image: linear-gradient(#25282b, #25282b);
}

.nav-item--desktop {
  margin-left: 2.8rem;
}

.nav-item--desktop:hover,
.logo:hover {
  background-size: 100% 3px;
}

body.dark-mode .nav-item--desktop,
body.dark-mode .logo {
  color: white;
  transition: color 1s;
  background-size: 0% 3px;
  background-repeat: no-repeat;
  transition: color 1s, background-size 0.3s;
  background-position: 0 100%;
  background-image: linear-gradient(#ffffff, #ffffff);
}

body.dark-mode .nav-item--desktop:hover,
body.dark-mode .logo:hover {
  background-size: 100% 3px;
}

@media only screen and (min-width: 1024px) {
  .navBar-container {
    padding: 1.6rem calc(5% + 3.8rem);
  }
}

@media only screen and (min-width: 1440px) {
  .navBar-container {
    padding: 1.6rem calc(10% + 3.8rem);
  }
}
